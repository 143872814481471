import Vue from 'vue'
import store from '../store';
import VueRouter from 'vue-router'
import {request} from "../helpers";

Vue.use(VueRouter);

const router = new VueRouter({
  routes : [
	  {path: '/', redirect: '/login'},
	  {
		  path    : '*',
		  redirect: '/calendario',
	  },
	  {
		  path:'', component: ()=> import("../components/Header"), props: true, meta: {auth : true},
		  children: [
			  {path: '/resumen', component: ()=>import("../components/resumen/Resumen"), name: 'resumen'},
			  {path: '/booking-list', component: ()=>import("../components/listado/Listado"), name: 'listado',
				  children:[{path:'filters', name: 'listado.filters'}]
			  },
			  {path: '/app-info', component: () => import('../components/Appinfo.vue'), name: 'appinfo'},
			  {path: '/calendario', component: ()=> import("../components/calendario/Calendario"), name: 'calendario'},
			  {path: '/create', component: () => import("../components/calendario/create/CalendarioCreateBooking"), name: 'calendar.create'},
			  {path: '/lock', component: () => import("../components/calendario/create/CalendarioCreateBloqueo"), name: 'calendar.lock', props : true,},
			  {path: '/lock/:id?', component: () => import("../components/calendario/create/CalendarioEditBloqueo"), name: 'calendar.lock.edit', props : true,},
			  {path: '/eventos', component: () => import("../components/calendario/BookingUpdates"), props: true, name: 'updates',
				  children: [
					  {path: "/nuevas_hoy", component: ()=> import("../components/calendario/tabs-reserva/TabNuevasHoy"), props    : true, name     : 'nuevas_hoy'},
					  {path: "/canceladas_hoy", component: ()=> import("../components/calendario/tabs-reserva/TabCanceladasHoy"), props    : true, name     : 'canceladas_hoy'},
				  ]
			  },
			  {path    : '/Reserva/:id', component: ()=>import("../components/calendario/Booking"), props: true, name: 'booking',
				  children: [
					  {path: "/Reserva/:id/detalle", component: ()=> import ('../components/calendario/tabs-reserva/TabDetalle'), props : true, name : 'detalles'},
					  {path: "/Reserva/:id/cargos", component: ()=> import ('../components/calendario/tabs-reserva/TabCargos'), props : true, name : 'cargos'},
					  {path: "/Reserva/:id/historial", component: ()=> import ('../components/calendario/tabs-reserva/TabHistorial'), props : true, name : 'historial'}
				  ]
			  }
		  ]
	  },
	  {
		  path    : '/login', component: () => import('../components/login/Login.vue'), name: 'login', props: true,
		  children: [
			  {path: "", component: () => import('../components/login/LoginEstablecimiento.vue'), name     : 'login-establecimiento', props 	 : true},
			  {path: ":slug", component: () => import('../components/login/LoginUsuario.vue'), name     : 'login-usuario', props    : true}
		  ]
	  },
  ]
});


router.beforeEach(async function(to, from, next){
	if(store.state.timer_interval){
		clearTimeout(store.state.timer_interval)
	}
	store.state.showNavigation = false;

	if (to.matched.some(record => record.meta.auth)) {
		if(store.state.jwt){
			let r =   await request(base_url+'/api/check_session?jwt='+store.state.jwt);
			if (r.ok){
				store.commit('setJwtToken', r.r.data.jwt);
				next();
			}else{
				await store.dispatch('logout');
			}
		}else{
			next({name  : "login-usuario", params: {slug: store.state.slug, lg: store.state.active_lang}});
		}
	}else{
		next();
	}

});

export default router
