import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
import jsonToQuery from 'json-to-http-query-string'
import {request} from "@/helpers";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store(
	{
		state    : {
			jwt : localStorage.getItem('jwt') || null,
			keys : {
				header: 0,
				calendario :0,
				booking: 0,
				counters : 0
			},
			timer_interval  : null,
			show_modal_timmer : false,
			slug 			 : '',
			count            : 0,
			loading          : true,
			reserva          : {},
			active_tab       : '',
			booking          : {},
			lang             : language,
			active_lang,
			establecimiento,
			usuario,
			base_url,
			categorias,
			selectedDate     : '',
			categoriasActivas: [],
			showSidepanel : false,
			showNavigation: false,
			showProfile : false,
			showBookingCreate : false,
			snackbar : {
				show: false,
				text: 'hola mundo',
				duration : 3000,
			},
			badge_channel_creadas : 0,
			badge_channel_canceladas : 0,
			calendarDate : null,
			calendarData : {},
			create_data:{
				date: null,
				tipo : '',
				categoria :'',
				cuarto : '',
				persistent : false
			}
		},
		mutations: {
			loading(state, on_off) {
				state.loading = on_off;
			},
			setTitle(state, tabname) {
				state.active_tab = tabname
			},
			setCalendarData(state,data){
				state.show_modal_timmer = false;
				const Store = this;
				Store.dispatch('calendar_timmer');
				state.calendarData = data;
			},

			setTimmer(state, data){
				if(!state.timer_interval){
					clearTimeout(state.timer_interval)
				}
				state.timer_interval = data;
			},
			setBooking(state, booking) {
				state.booking = booking;
			},
			setCategoriasActivas(state, ca) {
				state.categoriasActivas = ca;
				categoriasActivas = ca;
			},
			
			setCanceladasCreadas(state, data){
				state.badge_channel_creadas = data.nuevas;
				state.badge_channel_canceladas = data.canceladas;
				state.keys.counters += 1;
			},

			setJwtToken(state,data){
				state.jwt = data;
				localStorage.setItem('jwt', data);
			},
			
			setServerData(state, request){
				state.lang  = request.language;
				state.active_lang = request.active_lang;
				state.establecimiento = request.establecimiento;
				state.usuario = request.usuario;
				state.categorias = request.categorias;
				
				language = request.language;
				active_lang = request.active_lang;
				establecimiento = request.establecimiento;
				usuario = request.usuario;
				categorias = request.categorias;
				
				document.querySelector("#colorsCalenadar").innerHTML = request.colors;

				state.keys.header += 1;
				state.keys.body += 1;
				
			},
			setCalendarDate(state, data){
				state.calendarDate = data;
			},
			setSnackBar(state, data){
				state.snackbar = {...state.snackbar, ...data}
			}
		},
		getters:{
			islogged : state => ((state.jwt != null)),
			habitaciones: state => { return state.calendarData.habitaciones},
			privadas : state => (state.calendarData.habitaciones.filter( (h, i) =>  h.tipo == "privada" )[0]),
			compartidas : state => (state.calendarData.habitaciones.filter( (h, i) =>  h.tipo == "compartida" )[0]),
		},
		
		actions: {
			calendar_timmer(context){
				context.commit('setTimmer', setTimeout(function () {
					context.state.show_modal_timmer = true;
					context.dispatch('calendar_timmer');
				},600000)) // 10 mins
			},
			logout(context){
				localStorage.removeItem('jwt');
				context.state.jwt = null;
				
				let slug = context.state.slug;
				let lang = context.state.active_lang;
				router.push({name: 'login-usuario', params:{ slug, lang}})
			},
			closeSnackbar(context){
				context.commit('setSnackBar',{
					show: false,
					text: 'hola mundo',
					duration : 3000,
				})
			},
			async loginAs(context, payload){
				let endpoint = `${context.state.base_url}/entrar/change?hotel=${payload.id}&view=json&jwt=${context.state.jwt}&lg=${context.state.active_lang}`;
				context.state.loading = true;
				let response = await request(endpoint);
				
				if(response.ok){
					context.commit('setJwtToken', response.r.data.jwt);
					context.dispatch('getServerData');
				}
				
				context.state.loading = false;
			},
			async getServerData(context, payload = {}){
				let lang = (context.state.active_lang) || "es";
				context.state.loading = true;
				let r = await request(`${this.state.base_url}/api/data?lang=${lang}&view=json&jwt=${context.state.jwt}`);
				if (r.ok) {
					context.commit('setServerData', r.r.data);
				}
				context.state.loading = false;
			},

			async refreshToken(context){
				let r =   await request(base_url+'/api/check_session?jwt='+context.state.jwt);
				if (r.ok){
					context.commit('setJwtToken', r.r.data.jwt);
				}else{
					context.dispatch('logout');
				}
			},

			async getCalendar(context) {
				if(empty(context.state.calendarDate)){
					context.state.calendarDate = new Date();
				}
				App.lobbyCalendar.getBookings(App.lobbyCalendar.date);
			},
			
			async getBooking(context, payload) {
				context.state.loading = true;
				let r = await request(`${this.state.base_url}/reserva?id=${payload.id}&view=json&jwt=${context.state.jwt}`);
				if (r.ok) {
					context.commit('setBooking', r.r.data);
				} else {
					// mostrar mensaje de error de transaccion
				}
				context.state.loading = false;
			},
		}
	})
