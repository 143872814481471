import store from "../store";

export const request = function(url, data, redirect = true){
	
	return new Promise(function (resolve, reject) {
		let token = localStorage.getItem('jwt');
		let config = {
			headers: {Authorization: `Bearer ${token}`}
		};


		axios.post(url, data)
			 .then(function (r) {
				 resolve({ok: true, r: r})
			 })
			 .catch(function (e) {
			 	// si la respeusta es 401 entoces se que el servidor no esta logueado

				 if(e.response && e.response.status == 401) {
				 	App.vue.$store.dispatch('logout');
				 }

			 	resolve({ok: false, r: e.response})

			 })
	});
};

export const toFormData = function(item){
	var form_data = new FormData();
	
	for ( var key in item ) {
		form_data.append(key, item[key]);
	}
	
	return form_data;
	
}


export const Utility = {
	convertModelToFormData(val, formData = new FormData, namespace = '') {
		if ((typeof val !== 'undefined') && val !== null) {
			if (val instanceof Date) {
				formData.append(namespace, val.toISOString());
			} else if (val instanceof Array) {
				for (let i = 0; i < val.length; i++) {
					this.convertModelToFormData(val[i], formData, namespace + '[' + i + ']');
				}
			} else if (typeof val === 'object' && !(val instanceof File)) {
				for (let propertyName in val) {
					if (val.hasOwnProperty(propertyName)) {
						this.convertModelToFormData(val[propertyName], formData, namespace ? `${namespace}[${propertyName}]` : propertyName);
					}
				}
			} else if (val instanceof File) {
				formData.append(namespace, val);
			} else {
				formData.append(namespace, val.toString());
			}
		}
		return formData;
	}
}


export const empty = function (variable) {
	return (
		(typeof variable == 'undefined') //undefined
		||
		(variable == null) //null
		||
		(variable == false) //!variableiable     //false
		||
		(variable.length == 0) ||
		(JSON.stringify(variable) == JSON.stringify({})) //empty
		||
		(variable.toString().trim() == "") //empty
		||
		(variable.toString().replace(/\s/g, "") == "") //empty
		||
		(!/[^\s]/.test(variable)) //empty
		||
		(/^\s*$/.test(variable)) //empty
	);
}


export const loadScripts = function (){
	
	var list = [
		base_url+'public/js/calendario/calendario.js?v=23.25',
	]
	
	list.forEach((url)=>{
		let script = document.createElement("script");
		script.type = "text/javascript";
		
		if (script.readyState){  //IE
			script.onreadystatechange = function(){
				if (script.readyState == "loaded" ||
					script.readyState == "complete"){
					script.onreadystatechange = null;
				}
			};
		} else {  //Others
			script.onload = function(){
			
			};
		}
		
		script.src = url;
		document.getElementsByTagName("head")[0].appendChild(script);
	})
	
}

export const avatar = function(name){
	name = name.toUpperCase().trim();
	var iniciales = 'H';
	var nombre = name.split(' ');
	if (nombre.length == 1) {
		iniciales = nombre[0].charAt(0) + nombre[0].charAt(1);
	}
	
	if (nombre.length == 2 || nombre.length == 3) {
		iniciales = nombre[0].charAt(0) + nombre[1].charAt(0);
	}
	
	if (nombre.length > 3) {
		iniciales = nombre[0].charAt(0) + nombre[2].charAt(0);
	}
	
	return iniciales
}

