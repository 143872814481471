<template>
  <div id="app">
    <div class="md-loading" v-if="$store.state.loading">
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
    <md-snackbar
             md-position="center"
            :md-duration="$store.state.snackbar.duration"
            :md-active.sync="$store.state.snackbar.show"
            :md-persistent = '$store.state.snackbar.persistent'
    >
      <span>{{$store.state.snackbar.text}}</span>
      <md-button class="md-primary" @md-close="$store.dispatch('closeSnackbar')" @click="$store.dispatch('closeSnackbar')">{{lang.cerrar}}</md-button>
    </md-snackbar>
    <router-view/>
  </div>
</template>
<script>
  export default {
    name: 'Home',
    computed : {
      lang(){return this.$store.state.lang},
    }
  }
</script>

<style>
  #app{
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
</style>
